import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['source', 'input', 'button']


  copyRichText(html) {
    const htmlContent = html;
    // Create a temporary element to hold the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    document.body.appendChild(tempElement);
    // Select the HTML content
    const range = document.createRange();
    range.selectNode(tempElement);
    // Copy the selected HTML content to the clipboard
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
    document.body.removeChild(tempElement);
  }


  copy() {
    const text = this.sourceTarget.innerHTML || this.sourceTarget.value
    if (this.sourceTarget.tagName === 'PRE') {
      navigator.clipboard.writeText(text)
    } else {
      this.copyRichText(text)
    }
    this.buttonTarget.innerHTML = '<span id="copied">Copied</span>'


    setTimeout(function () {
      document.getElementById('copied').outerHTML = 'Copy to Clipboard'
    }, 1500)
  }
}